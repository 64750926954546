import React from "react";
import AdminLoginPage from "../../components/ui/AdminLoginPage";

const AdminLoginContainer = () => {
  return (
    <>
      <AdminLoginPage />
    </>
  );
};

export default AdminLoginContainer;
