import React from "react";
import HomeContainer from "../../containers/HomeContainer";
import { Helmet } from "react-helmet-async";
const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>ENT Specialists Gurdaspur | Best ENT Doctor in Gurdaspur</title>
        <meta
          name="title"
          content="ENT Specialists Gurdaspur | Best ENT Doctor in Gurdaspur"
        />
        <meta
          name="description"
          content="Kashyap ENT Hopsital is the best Hospital in Gurdaspur that have the specialist ENT Surgeon and doctors for all type of Ear, Nose and Throat problems. "
        />
        <meta name="theme-color" content="#008f68" />
      </Helmet>

      <div className="home_wrp">
        <HomeContainer />
      </div>
    </>
  );
};

export default HomePage;
