import React from 'react'
import { Spinner } from 'react-bootstrap'
import "./Loader.scss"

const Loader = () => {
  return (
    <div className='loader_wrp'>
      <Spinner animation='grow' variant="danger"/>
    </div>
  )
}

export default Loader