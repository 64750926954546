import React, { useState, useRef } from "react";
import { useToasts } from "react-toast-notifications";
import "./Appointment.scss";
import contactIcon from "../../../images/contactIcon.webp";
import btnIcon from "../../../images/about-button-icon.webp";
import { AppointmentPostAction } from "../../../Api/Appointment";
import { useNavigate } from "react-router-dom";
import sendMail from "../../../utils/sendMail";

const Appointment = () => {
  const navigate = useNavigate()
  const name = useRef();
  const email = useRef();
  const number = useRef();
  const reason = useRef();
  const { addToast } = useToasts();
  const [isValidate, setIsValidate] = useState({});
  const [input, setInput] = useState({
    name: "",
    mobile: "",
    email: "",
    reason: "",
  });

  const validate = (type, value) => {
    switch (type) {
      case "name":
        if (value.length < 3) {
          name.current.parentNode.style.border = "2px solid red";
          setIsValidate({ ...isValidate, name: false });
        } else {
          name.current.parentNode.style.border = "2px solid green";
          setIsValidate({ ...isValidate, name: true });
        }
        break;
      case "email":
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
          email.current.parentNode.style.border = "2px solid red";
          setIsValidate({ ...isValidate, email: false });
        } else {
          email.current.parentNode.style.border = "2px solid green";
          setIsValidate({ ...isValidate, email: true });
        }
        break;

      case "mobile":
        if (value.length < 10 || isNaN(value)) {
          number.current.parentNode.style.border = "2px solid red";
          setIsValidate({ ...isValidate, mobile: false });
        } else {
          number.current.parentNode.style.border = "2px solid green";
          setIsValidate({ ...isValidate, mobile: true });
        }
        break;

      case "reason":
        if (!value) {
          reason.current.parentNode.style.border = "2px solid red";
          setIsValidate({ ...isValidate, reason: false });
        } else {
          reason.current.parentNode.style.border = "2px solid green";
          setIsValidate({ ...isValidate, reason: true });
        }
        break;

      default:
        break;
    }
  };

  const handleInput = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    validate(e.target.name, e.target.value);
  };

  const handleForm = async (e) => {
    e.preventDefault();
    try {
      if (
        isValidate.name &&
        isValidate.mobile &&
        isValidate.reason
      ) {
        const res = await AppointmentPostAction(input)
        if (res.data) {
          sendMail(input, 'appointment')
          setInput({
            name: "",
            mobile: "",
            email: "",
            reason: "",
          });

          name.current.parentNode.style.border = "none";
          email.current.parentNode.style.border = "none";
          number.current.parentNode.style.border = "none";
          reason.current.parentNode.style.border = "none";
          navigate('/greet')
        }
        else {
          addToast("something went worng", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      } else {
        addToast("Please Fill all the Fields!", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (error) {
      addToast("error", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };


  return (
    <div className="appointment_wrp">
      <div className="appointment_container">
        <div className="appontment_details">
          <form onSubmit={handleForm}>
            <div>
              <p> Book Appointment</p>
              <h4> We are here for you</h4>
            </div>

            <div className="appointment_input">
              <img src={contactIcon} alt="contactIcon" />
              <label htmlFor="a_name">
                Patient Name <span>*</span>
              </label>
              <input
                value={input.name}
                id="a_name"
                name="name"
                ref={name}
                onChange={handleInput}
              />
            </div>
            <div className="appointment_input">
              <img src={contactIcon} alt="contactIcon" />
              <label htmlFor="a_email">
                Your Email 
              </label>
              <input
                id="a_email"
                value={input.email}
                name="email"
                ref={email}
                onChange={handleInput}
              />
            </div>
            <div className="appointment_input">
              <img src={contactIcon} alt="contactIcon" />
              <label htmlFor="a_mobile">
                Your Mobile <span>*</span>
              </label>
              <input
                id="a_mobile"
                ref={number}
                name="mobile"
                type="tel"
                maxLength="10"
                value={input.mobile}
                onChange={handleInput}
              />
            </div>
            <div className="appointment_input">
              <img src={contactIcon} alt="contactIcon" />
              <label htmlFor="a_reason">
                Disease <span>*</span>
              </label>
              <select
                id="a_reason"
                value={input.reason}
                name="reason"
                ref={reason}
                onChange={handleInput}
              >
                <option value="">Select Disease</option>
                <option value="Physical diseases">Physical diseases</option>
                <option value="Mental diseases">Mental diseases</option>
                <option value="Infection diseases">Infection diseases</option>
              </select>
            </div>
            <button>
              Make Appointment <img src={btnIcon} alt="icon" />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Appointment;
