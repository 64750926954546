import React from "react";
import "./about.scss";
import btnIcon from "../../../images/about-button-icon.webp";
import { useNavigate } from "react-router-dom";

const About = ({ container, heading, para, image, imageAlign, alt }) => {
  const navigate = useNavigate()


  return (
    <div className={`about_wrp ${imageAlign === "right" ? "reverse" : ""}`}>
      <div className="about_left">
        <div className="img_wrp">
          <img loading="lazy" src={image} alt={alt}/>
        </div>
      </div>
      <div className="about_right d-flex justify-content-center flex-column">
        <div className="about_right_inner">
          <h6>{heading}</h6>
          <div dangerouslySetInnerHTML={{ __html: para }}></div>

          {container === "home" && (
            <button onClick={() => {navigate('/about')}}>
              Learn More <img src={btnIcon} alt="icon" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default About;
