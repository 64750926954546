import React from "react";
import { string } from "prop-types";
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Row } from "simple-flexbox";
import { createUseStyles, useTheme } from "react-jss";
// import { SidebarContext } from "../../../../hooks/useSidebar";
import SLUGS from "../../../../resources/slugs";
// import { IconBell, IconSearch } from "../../../../assets/icons";
import DropdownComponent from "../dropdown";
import { useLocation } from "react-router-dom";
const useStyles = createUseStyles((theme) => ({
  avatar: {
    height: 35,
    width: 35,
    minWidth: 35,
    borderRadius: 50,
    marginLeft: 14,
    border: `1px solid ${theme.color.lightGrayishBlue2}`,
    "@media (max-width: 768px)": {
      marginLeft: 14,
    },
  },
  container: {
    height: 40,
  },
  name: {
    ...theme.typography.itemTitle,
    textAlign: "right",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  separator: {
    borderLeft: `1px solid ${theme.color.lightGrayishBlue2}`,
    marginLeft: 32,
    marginRight: 32,
    height: 32,
    width: 2,
    "@media (max-width: 768px)": {
      marginLeft: 14,
      marginRight: 0,
    },
  },
  title: {
    ...theme.typography.title,
    "@media (max-width: 1080px)": {
      marginLeft: 50,
    },
    "@media (max-width: 468px)": {
      fontSize: 20,
    },
  },
  iconStyles: {
    cursor: "pointer",
    marginLeft: 25,
    "@media (max-width: 768px)": {
      marginLeft: 12,
    },
  },
}));

function HeaderComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const classes = useStyles({ theme });

  let title;
  switch (true) {
    case location.pathname === "/dashboard":
      title = "Dashboard";
      break;
    case location.pathname === "/dashboard/gallery":
      title = "Gallery List";
      break;
    case location.pathname === "/dashboard/appointment":
      title = "Appointment List";
      break;
    case location.pathname === "/dashboard/about":
      title = "About Us";
      break;
    case location.pathname === "/dashboard/contact":
      title = "Contacts";
      break;
    case location.pathname === "/dashboard/resource":
      title = "Resources";
      break;
    case location === SLUGS.settings:
      title = "Settings";
      break;
    default:
      title = "";
  }

  return (
    <Row
      className={classes.container}
      vertical="center"
      horizontal="space-between"
    >
      <span className={classes.title}>{title}</span>
      <Row vertical="center">
        <DropdownComponent
          label={
            <>
              <span className={classes.name}>Admin</span>
              <img
                src="https://avatars3.githubusercontent.com/u/21162888?s=460&v=4"
                alt="avatar"
                className={classes.avatar}
              />
            </>
          }
          options={[
            {
              label: "Logout",
              onClick: () => navigate("/admin"),
            },
          ]}
          position={{
            top: 52,
            right: -6,
          }}
        />
      </Row>
    </Row>
  );
}

HeaderComponent.propTypes = {
  title: string,
};

export default HeaderComponent;
