import React from "react";
import "./card.scss";

const Card = ({ container, data }) => {
  return (
    <>
      {data.map((item, index) => {
        const { heading, text } = item;


        return (
          <div
            className={`col-lg-4 col-md-6 col-12 px-2 card_wrp my-3`}
            key={new Date().getTime() + index}
          >
            <div className="card border-0 p-4">
              <div className="top">
                <div className="image">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="Group 11">
                      <circle
                        id="Ellipse 5"
                        cx="40"
                        cy="40"
                        r="39.5"
                        stroke="#FC1B15"
                        strokeDasharray="2 2"
                      />
                      <circle
                        id="Ellipse 6"
                        cx="11.5"
                        cy="67.5"
                        r="5.5"
                        fill="#FC1B15"
                      />
                      <circle
                        id="Ellipse 7"
                        cx="65.5"
                        cy="10.5"
                        r="5.5"
                        fill="#FC1B15"
                      />
                    </g>
                  </svg>

                  {container === "about" ? (
                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.735 15.8189C20.1633 15.8189 18.6428 15.5633 17.2244 15.1033C16.7772 14.95 16.2789 15.065 15.9339 15.41L13.9278 17.9272C10.3117 16.2022 6.92556 12.9439 5.12389 9.2L7.61556 7.07889C7.96056 6.72111 8.06278 6.22278 7.92222 5.77556C7.44944 4.35722 7.20667 2.83667 7.20667 1.265C7.20667 0.575 6.63167 0 5.94167 0H1.52056C0.830556 0 0 0.306667 0 1.265C0 13.1356 9.87722 23 21.735 23C22.6422 23 23 22.195 23 21.4922V17.0839C23 16.3939 22.425 15.8189 21.735 15.8189Z"
                        fill="#FC1B15"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="26"
                      height="24"
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        id="Vector"
                        d="M13 24L11.115 22.2736C4.42 16.1657 0 12.1373 0 7.19346C0 3.16512 3.146 0 7.15 0C9.412 0 11.583 1.0594 13 2.73352C14.417 1.0594 16.588 0 18.85 0C22.854 0 26 3.16512 26 7.19346C26 12.1373 21.58 16.1657 14.885 22.2866L13 24Z"
                        fill="#FC1B15"
                      />
                    </svg>
                  )}
                </div>
              </div>

              <div className="down">
                {heading === "Whatsapp" && <a href="https://api.whatsapp.com/send?phone=919780048003"><div className="down">
                  <h5 className="my-2">{heading}</h5>

                  <p dangerouslySetInnerHTML={{ __html: text }}></p>
                </div>
                </a>}
                {heading === "Email" && <a href="mailto:kashyapdoc@yahoo.com"><div className="down">
                  <h5 className="my-2">{heading}</h5>

                  <p dangerouslySetInnerHTML={{ __html: text }}></p>
                </div>
                </a>}
                {heading === "Phone" && <a href="tel:9417281799"><div className="down">
                  <h5 className="my-2">{heading}</h5>

                  <p dangerouslySetInnerHTML={{ __html: text }}></p>
                </div>
                </a>}
                {heading !== "Whatsapp" && heading !== "Email" && heading !== "Phone" && <div className="down">
                  <h5 className="my-2">{heading}</h5>

                  <p dangerouslySetInnerHTML={{ __html: text }}></p>
                </div>}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Card;
