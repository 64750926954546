import React from "react";
import About from "../../components/ui/About";
import ContactForm from "../../components/ui/Contact";
import ContactNo from "../../components/ui/contact-no";
import Specialities from "../../components/ui/Specialities/";
import Appointment from "../../components/ui/appointment";
import FAQ from "../../components/ui/FAQ";
import Feedback from "../../components/ui/Feedback";
import aboutImage from "../../images/about-home-page.webp";
import choiceImage from "../../images/Choice.webp";
const HeroSection = React.lazy(() => import("../../components/ui/HeroSection"));

const HomeContainer = () => {
  return (
    <div>
      <HeroSection />
      <ContactForm />
      <About
        container="home"
        heading="About Us"
        para=' 
        <h2>Medical care that leaves no stone unturned</h2>
        <p className="first_text">
        We are a speciality hospital providing a fleet of ear, nose and throat consultation and surgical treatments. Our highly qualified doctors, nurses, paramedics and administrative staff work tirelessly for the welfare of the patients and aim to approach the best-in-town medical care. 
              </p>
              <p className="last_text">
              Kashyap Hospital is located in Sant Nagar, Dorangla Road Gurdaspur and enhances the quality of life through visionary treatment options. We believe in technological interventions and hence we are equipped with high-tech laboratories, digital X-rays and 24/7 pharmacy facilities. Our sensitive care approach has helped people recover with modern medicine protocols. Kashyap Hospital was founded in the year 2006 encompassing major ENT services with endoscopes and microscopes.  

              </p>'
        image={aboutImage}
        imageAlign="left"
        alt="ENT specialists in Gurdaspur"
      />
      <Specialities />
      <ContactNo />
      <About
        container="home"
        heading="Care of Kids"
        para='
        <h2>Why choose Kashyap ENT clinic?
        </h2>
        <p class="first_text">At Kashyap ENT clinic, we approach health concerns with a sensitive approach. Our aim is to deliver the best medical care for adults and children. We are a super speciality ENT hospital in Gurdaspur with highly qualified ENT specialists for your Medicare. Moreover, we believe in integrating advanced medical technology into our clinics and surgical rooms.
        </p>
        <p class="last_text">Our ENT Specialists work for premium and world-class ENT services with empathy and excellence. Our comprehensive treatment planning allows us to treat challenging ENT problems with a successful outcome.   
        </p>
        <p>
        Inquire about our expert medical care online or contact us at +91 94172 81799 to schedule a consultancy!
        </p>
        '
        image={choiceImage}
        imageAlign="right"
        alt=""
      />
      <FAQ />
      <Appointment />
      <Feedback />
    </div>
  );
};
export default HomeContainer;
