import { Suspense, lazy } from "react";
import "./App.css";
import HomePage from "./page/home";
import { Routes, Route, Navigate } from "react-router-dom";

// import Header from "./components/layouts/header";
// import About from "./page/about";
// import Contact from "./page/contact";
// import Gallery from "./page/gallery";
// import Resource from "./components/ui/resourceComponent";
// import Treatment from "./page/treatment";
// import Footer from "./components/layouts/footer";
// import GoToTop from "./components/ui/ScrollToTop/ScrollToTop";
// import ThankYouPage from "./components/ui/ThankYouPage";

const Header = lazy(() => import("./components/layouts/header"));
const About = lazy(() => import("./page/about"));
const Contact = lazy(() => import("./page/contact"));
const Gallery = lazy(() => import("./page/gallery"));
const Treatment = lazy(() => import("./page/treatment"));
const Resource = lazy(() => import("./components/ui/resourceComponent"));
const GoToTop = lazy(() => import("./components/ui/ScrollToTop/ScrollToTop"));
const Footer = lazy(() => import("./components/layouts/footer"));
const ThankYouPage = lazy(() => import("./components/ui/ThankYouPage"));

function App() {
  return (
    <div className="App">
      <Suspense fallback={<>Loading...</>}>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="resource" element={<Resource />} />
          <Route path="treatment" element={<Treatment />} />
          <Route path="greet" element={<ThankYouPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <GoToTop /> <Footer />
      </Suspense>
    </div>
  );
}

export default App;
