import React from "react";
import Gallery from "../../Gallery";

const PhotoGallery = () => {
  return (
    <>
      <Gallery container={'admin-login'} isPublic={false} />
    </>
  );
};

export default PhotoGallery;
