import React, { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { spinnerAction } from "../../../../redux/spinner";
import {
  ResourcePostAction,
  ResourceGetBeforeUpdate,
  ResourceUpdateAction,
} from "../../../../Api/Resource";
import btnIcon from "../../../../images/about-button-icon.webp";

import "./UploadResourceModal.scss";
import { useEffect } from "react";

const UploadResourceModal = (props) => {
  const imageName = useRef();
  const pdfName = useRef();

  const [msg, setMsg] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  const [form, setForm] = useState({
    title: "",
    pdf_file: "",
    image: "",
    description: "",
  });
  const { addToast } = useToasts();
  const adminLoginReducer = useSelector((state) => state.adminLoginReducer);

  const dispatch = useDispatch();

  const handleResourceUpload = async (e) => {
    e.preventDefault();
    dispatch(spinnerAction(true));
    try {
      const token = adminLoginReducer.token;
      let res = "";
      setMsg("Wait..");
      if (props.isUpdate) {
        res = await ResourceUpdateAction(props.updateResourceId, form, token);
      } else {
        res = await ResourcePostAction(form, token);
      }
      if (res.data) {
        setMsg("");
        props.onHide(true);
        setForm({
          category: null,
          image: "",
        });
        dispatch(spinnerAction(false));
        addToast(
          `Resource ${props.isUpdate ? "Updated" : "Created"} successfully`,
          {
            appearance: "success",
            autoDismiss: true,
          }
        );
      } else {
        setMsg("");
        props.onHide(true);
        addToast("something went worng", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (error) {
      props.onHide(true);
      addToast("something went worng", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleFile = (e) => {
    setForm({ ...form, [e.target.name]: e.target.files[0] });
  };

  const changeUrlToByte = async (url) => {
    let res = await fetch(url);
    let file;
    const data = await res.blob();
    if (data.type === "image/png") {
      file = new File([data], "image.webp", {
        type: "image/png",
      });
    }

    if (data.type === "image/jpg") {
      file = new File([data], "image.jpg", {
        type: "image/jpg",
      });
    }

    if (data.type === "image/jpeg") {
      file = new File([data], "image.jpeg", {
        type: "image/jpeg",
      });
    }

    if (data.type === "application/pdf") {
      file = new File([data], "myFile.pdf", {
        type: "application/pdf",
      });
    }
    return file;
  };

  useEffect(() => {
    (async () => {
      if (props.isUpdate && props.updateResourceId) {
        setIsLoad(true);
        const res = await ResourceGetBeforeUpdate(props.updateResourceId);

        let pdfFilePromise = changeUrlToByte(
          `${process.env.REACT_APP_IMG_URL}${res.data[0].pdf_file}`
        )

        let imageFilePromise = changeUrlToByte(
          `${process.env.REACT_APP_IMG_URL}${res.data[0].image}`
        )

        const [pdfFile, imageFile] = await Promise.all([pdfFilePromise, imageFilePromise])
        

        setForm({
          title: res.data[0].title,
          pdf_file: pdfFile,
          image: imageFile,
          description: res.data[0].description,
        });

        setIsLoad(false);
      }
    })();

    return () => {
      setForm({
        title: "",
        pdf_file: "",
        image: "",
        description: "",
      });
    };
  }, [props.updateResourceId, props.isUpdate]);

  if (isLoad)
    return (
      <>
        <Modal
          show={props.show}
          onHide={props.onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="upload_resource_modal_wrp"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Create Resource
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="upload_resource_wrp">
              <h6 className="text-center">Loading...</h6>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="upload_resource_modal_wrp"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Resource
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="upload_resource_wrp">
          <form onSubmit={handleResourceUpload}>
            <div className="field_group">
              <div className="input_group">
                <label htmlFor="title">
                  Your Title <span>*</span>
                </label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  value={form.title}
                  onChange={handleInput}
                />
              </div>

              <div className="input_group">
                <label htmlFor="file">
                  Your File <span>*</span>
                </label>
                <input
                  type="file"
                  accept=".pdf"
                  name="pdf_file"
                  id="file"
                  ref={pdfName}
                  onChange={handleFile}
                />
              </div>

              <div className="input_group">
                <label htmlFor="image">
                  Your Thumbnail <span>*</span>
                </label>
                <input
                  type="file"
                  accept=".webp,.jpg,.jpeg"
                  name="image"
                  id="image"
                  ref={imageName}
                  onChange={handleFile}
                />
              </div>

              <div className="input_group">
                <label htmlFor="description">
                  Your Descripriton <span></span>
                </label>
                <textarea
                  name="description"
                  value={form.description}
                  id="description"
                  onChange={handleInput}
                ></textarea>
              </div>
            </div>
            {msg && <p>{msg}</p>}
            <button className="submitBtn" type="submit">
              {props.isUpdate ? "Update" : "Create"}
              <img src={btnIcon} alt="icon" />
            </button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UploadResourceModal;
