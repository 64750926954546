import React from 'react';
import { Row } from 'simple-flexbox';
import { createUseStyles, useTheme } from 'react-jss';
import IconLogo from '../../../../images/logo.webp';

const useStyles = createUseStyles((theme) => ({
    container: {
        marginLeft: 32,
        marginRight: 32
    },
    title: {
        color: theme.color.grayishBlue,
        opacity: 0.7,
        marginLeft: 12
    }
}));

function LogoComponent() {
    const theme = useTheme();
    const classes = useStyles({ theme });
    return (
        <Row className={classes.container} horizontal='center' vertical='center'>
            <img src={IconLogo} alt="logo" style={{width: 'auto', height: '2rem'}} />
            <span className={classes.title}>Kashyap hospital</span>
        </Row>
    );
}

export default LogoComponent;
