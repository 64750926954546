import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { CategoryGetAction, GalleryPostAction } from "../../../Api/gallery";
import btnIcon from "../../../images/about-button-icon.webp";
import { spinnerAction } from "../../../redux/spinner";

import "./UploadImageModal.scss";

const UploadImageModal = (props) => {
  const [categoryList, setCategoryList] = useState();
  const [msg, setMsg] = useState("");
  const [gallery, setGallery] = useState({
    category: null,
    image: "",
  });
  const { addToast } = useToasts();
  const adminLoginReducer = useSelector((state) => state.adminLoginReducer);
  const spinner = useSelector((state) => state.spinnerReducer);

  const dispatch = useDispatch();

  const handleImageUpload = async (e) => {
    e.preventDefault();
    dispatch(spinnerAction(true));
    try {
      const token = adminLoginReducer.token;
      setMsg("Wait..");
      const res = await GalleryPostAction(gallery, token);
      if (res.data) {
        setMsg("");
        props.onHide(true);
        setGallery({
          category: null,
          image: "",
        });
        dispatch(spinnerAction(false));
        addToast("image uploaded successfully", {
          appearance: "success",
          autoDismiss: true,
        });
      } else {
        setMsg("");
        props.onHide(true);
        addToast("something went worng", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (error) {
      props.onHide(true);
      addToast("something went worng", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const handleInput = (e) => {
    setGallery({ ...gallery, [e.target.name]: e.target.value });
  };
  const handleImageFile = (e) => {
    setGallery({ ...gallery, [e.target.name]: e.target.files[0] });
  };
  const getCategory = async () => {
    const res = await CategoryGetAction();
    setCategoryList(res);
  };

  useEffect(() => {
    getCategory();
  }, [props, spinner]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="upload_image_modal_wrp"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Upload Image
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="upload_image_wrp">
          <form onSubmit={handleImageUpload}>
            <div className="field_group">
              <div className="input_group">
                <label htmlFor="img">
                  Your Image <span>*</span>
                </label>
                <input
                  type="file"
                  accept=".jpg,.webp,.jpeg"
                  name="image"
                  id="img"
                  onChange={handleImageFile}
                />
              </div>
              <select onChange={handleInput} name="category" id="category">
                <option>select</option>
                {categoryList &&
                  categoryList.data.map((elem, index) => {
                    return (
                      <option key={elem.id + index} value={elem.id}>
                        {elem.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            {msg && <p>{msg}</p>}

            {gallery && gallery.category && gallery.image ? (
              <button className="submitBtn">
                Upload
                <img src={btnIcon} alt="icon" />
              </button>
            ) : (
              <button disabled className="submitBtn">
                Upload
                <img src={btnIcon} alt="icon" />
              </button>
            )}
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UploadImageModal;
