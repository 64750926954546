import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  CategoryGetAction,
  CategoryPostAction,
  CategoryDeleteAction,
} from "../../../Api/gallery";
import btnIcon from "../../../images/about-button-icon.webp";
import { spinnerAction } from "../../../redux/spinner";

import "./UploadImageModal.scss";

const CategoryModal = (props) => {
  const [category, setCategory] = useState({
    name: "",
  });
  const [deleteCategory, setDeleteCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const { addToast } = useToasts();
  const adminLoginReducer = useSelector((state) => state.adminLoginReducer);
  const spinner = useSelector((state) => state.spinnerReducer);

  const dispatch = useDispatch();

  const handleDeleteCategory = async (e) => {
    e.preventDefault();
    const token = adminLoginReducer.token
    dispatch(spinnerAction(true));
    try {
      const res = await CategoryDeleteAction(
        deleteCategory,
        token
      );
      if (res) {
        dispatch(spinnerAction(false));
      props.onHide(true);
        addToast("category deleted.", {
          appearance: "success",
          autoDismiss: true,
        });
      }
      console.log(res);
    } catch (error) {
      props.onHide(true);
      addToast("something went worng", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleCreateCategory = async (e) => {
    e.preventDefault();
    dispatch(spinnerAction(true));
    try {
      const token = adminLoginReducer.token;
      const res = await CategoryPostAction(category, token);
      if (res.data) {
        dispatch(spinnerAction(false));
      props.onHide(true);
        addToast("category created.", {
          appearance: "success",
          autoDismiss: true,
        });
      }
    } catch (error) {
      props.onHide(true);
      addToast("something went worng", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const getCategory = async () => {
    const res = await CategoryGetAction();
    setCategoryList(res);
  };

  useEffect(() => {
    getCategory();
  }, [props, spinner]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="upload_image_modal_wrp"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="create_category_wrp">
          <form onSubmit={handleCreateCategory}>
            <div className="input_group">
              <label htmlFor="category">
                Your Category <span>*</span>
              </label>
              <input
                type="text"
                name="category"
                id="category"
                value={category.name}
                onChange={(e) =>
                  setCategory({ ...category, name: e.target.value })
                }
              />
            </div>
            {category && category.name ? (
              <button className="submitBtn">
                Create
                <img src={btnIcon} alt="icon" />
              </button>
            ) : (
              <button disabled className="submitBtn">
                Create
                <img src={btnIcon} alt="icon" />
              </button>
            )}
          </form>
        </div>

        <div className="create_category_wrp">
          <form onSubmit={handleDeleteCategory}>
            <div className="input_group">
              <label htmlFor="category">
                Your Category <span>*</span>
              </label>

              <select
                onChange={(e) => setDeleteCategory(e.target.value)}
                name="tabsDelete"
                id="tabs"
                value={deleteCategory}
              >
                <option value="">Select</option>
                {categoryList.data &&
                  categoryList?.data.map((item, index) => {
                    return (
                      <option
                        key={new Date().getTime() + index}
                        value={item.id}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            {deleteCategory ? (
              <button className="submitBtn">
                Delete
                <img src={btnIcon} alt="icon" />
              </button>
            ) : (
              <button disabled className="submitBtn">
                Delete
                <img src={btnIcon} alt="icon" />
              </button>
            )}
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CategoryModal;
