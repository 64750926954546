import axios from "axios";
const url = process.env.REACT_APP_URL;

export const GalleryPostAction = async (data, token) => {
  try {
    const xhr = await axios.request({
      method: "post",
      url: `${url}gallery/`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data,
    });
    return xhr;
  } catch (error) {
    return error;
  }
};

export const GalleryGetAction = async (id) => {
  const res = await axios.get(`${url}gallery/${id}`);
  return res;
};

export const CategoryPostAction = async (data, token) => {
  try {
    const xhr = await axios.request({
      method: "post",
      url: `${url}category/`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return xhr;
  } catch (error) {
    return error;
  }
};

export const CategoryGetOnlyHaveImages = async () => {
  const res = await axios.get(`${url}gallery/`)
  return res
}

export const CategoryGetAction = async () => {
  const res = await axios.get(`${url}category/`);
  return res;
};

export const ImageDeleteAction = async (data, token) => {
  try {
    const xhr = await axios.request({
      method: "delete",
      url: `${url}gallery/${data}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return xhr;
  } catch (error) {
    return error;
  }
};
export const CategoryDeleteAction = async (data, token) => {
  try {
    const xhr = await axios.request({
      method: "delete",
      url: `${url}category/${data}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return xhr;
  } catch (error) {
    return error;
  }
};
