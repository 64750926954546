import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppointmentGetAction } from "../../../../Api/Appointment";
import { ContactUsGetApi } from "../../../../Api/ContactUs";
// import { SubscribeGetApi } from "../../../../Api/subscribe";

const DashboardContaint = () => {
  const [apointmentList, setApointmentList] = useState();
  const [conatctList, setContactList] = useState();
  // const [subscribeList, setSubscribeList] = useState();
  const navigate = useNavigate();
  const adminLoginReducer = useSelector((state) => state.adminLoginReducer);

  const Appointments = async (pageNumber) => {
    try {
      var token = adminLoginReducer.token;
      const apointment = await AppointmentGetAction(
        `?page=${pageNumber}`,
        token
      );
      setApointmentList(apointment.data);
    } catch (error) {
      navigate("/admin");
    }
  };
  const Contacts = async (pageNumber) => {
    try {
      var token = adminLoginReducer.token;
      const contacts = await ContactUsGetApi(`?page=${pageNumber}`, token);
      setContactList(contacts.data);
    } catch (error) {
      return error;
    }
  };

  // const Subscribes = async (pageNumber) => {
  //   try {
  //     const token = adminLoginReducer.token;
  //     const res = await SubscribeGetApi(`?page=${pageNumber}`, token);
  //     setSubscribeList(res.data);
  //   } catch (error) {
  //     return error;
  //   }
  // };
  useEffect(() => {
    Appointments(1);
    Contacts(1);
    // Subscribes(1);
  }, []);
  return (
    <div>
      <div>
        <h4>Appointments</h4>
        <div style={{ overflowX: "auto", width: "100%" }}>
          <Table striped bordered hover variant="ligth">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile </th>
                <th>reason</th>
              </tr>
            </thead>
            <tbody>
              {apointmentList &&
                apointmentList.data.map((elem, index) => {
                  return (
                    <tr key={elem.id}>
                      <td>{index + 1}</td>
                      <td>{elem.name}</td>
                      <td>{elem.email}</td>
                      <td>{elem.mobile}</td>
                      <td>{elem.reason}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
      <div>
        <h4>Contacts</h4>
        <div style={{ overflowX: "auto", width: "100%" }}>
          <Table striped bordered hover variant="ligth">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone </th>
                <th>Subject</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {conatctList &&
                conatctList.data.map((elem, index) => {
                  return (
                    <tr key={elem.id}>
                      <td>{index + 1}</td>
                      <td>{elem.name}</td>
                      <td>{elem.email}</td>
                      <td>{elem.phone}</td>
                      <td>{elem.subject}</td>
                      <td>{elem.message}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default DashboardContaint;
