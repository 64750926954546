import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  AppointmentGetAction,
  AppointmentGetTotalData,
} from "../../../../Api/Appointment";
import CSVDownload from "react-json-to-csv";
import "../dashboard.scss";

const AppointmentList = () => {
  const [state, setState] = useState(1);
  const adminLoginReducer = useSelector((state) => state.adminLoginReducer);
  const [apointmentList, setApointmentList] = useState();
  const [downloadData, setDownloadData] = useState([]);
  const { addToast } = useToasts();

  const handlePageChange = async (pageNumber) => {
    try {
      setState(pageNumber);
      var token = adminLoginReducer.token;
      const apointment = await AppointmentGetAction(
        `?page=${pageNumber}`,
        token
      );
      setApointmentList(apointment.data);
    } catch (error) {
      addToast("something went worng", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  useEffect(() => {
    handlePageChange(1);
    const getAllData = async () => {
      const res = await AppointmentGetTotalData(adminLoginReducer.token);
      setDownloadData(res.data.all_data);
    };
    getAllData();
  }, []);

  return (
    <div>
      <CSVDownload
        className="export_btn"
        data={downloadData}
        filename="appointment-list.csv"
      >
        Export
      </CSVDownload>
      <div style={{overflowX: 'auto', width: '100%'}}>
        <Table striped bordered hover variant="ligth">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile </th>
              <th>reason</th>
            </tr>
          </thead>
          <tbody>
            {apointmentList &&
              apointmentList.data.map((elem, index) => {
                return (
                  <tr key={elem.id}>
                    <td>{index + 1}</td>
                    <td>{elem.name}</td>
                    <td>{elem.email}</td>
                    <td>{elem.mobile}</td>
                    <td>{elem.reason}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      <div className="footer">
        {apointmentList && (
          <Pagination
            activePage={state}
            itemsCountPerPage={10}
            totalItemsCount={apointmentList.total_contacts}
            pageRangeDisplayed={
              apointmentList.total_page > 5 ? 5 : apointmentList.total_page
            }
            innerClass="innerClassForPagination"
            activeClass="activeClassForPagination"
            itemClass="itemClassForPagination"
            activeLinkClass="activeLinkClassForPagination"
            onChange={(page) => handlePageChange(page)}
          />
        )}

        <span>
          {apointmentList &&
            apointmentList.current_page * 10 -
              (10 - apointmentList.data.length)}{" "}
          out of {apointmentList && apointmentList.total_contacts}
        </span>
      </div>
    </div>
  );
};
export default AppointmentList;
