import React, { useState, useRef, useCallback } from "react";
import "./ContactForm.scss";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { AppointmentPostAction } from "../../../Api/Appointment";
import sendMail from "../../../utils/sendMail";

const ContactForm = () => {
  const navigate = useNavigate();
  const name = useRef();
  const email = useRef();
  const number = useRef();
  const reason = useRef();
  const { addToast } = useToasts();
  const [isValidate, setIsValidate] = useState({});
  const [input, setInput] = useState({
    name: "",
    mobile: "",
    email: "",
    reason: "",
  });

  const validate = (type, value) => {
    switch (type) {
      case "name":
        if (value.length < 3) {
          name.current.parentNode.style.border = "2px solid red";
          setIsValidate({ ...isValidate, name: false });
        } else {
          name.current.parentNode.style.border = "2px solid green";
          setIsValidate({ ...isValidate, name: true });
        }
        break;
      case "email":
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
          email.current.parentNode.style.border = "2px solid red";
          setIsValidate({ ...isValidate, email: false });
        } else {
          email.current.parentNode.style.border = "2px solid green";
          setIsValidate({ ...isValidate, email: true });
        }
        break;

      case "mobile":
        if (value.length < 10 || isNaN(value)) {
          number.current.parentNode.style.border = "2px solid red";
          setIsValidate({ ...isValidate, mobile: false });
        } else {
          number.current.parentNode.style.border = "2px solid green";
          setIsValidate({ ...isValidate, mobile: true });
        }
        break;

      case "reason":
        if (!value) {
          reason.current.parentNode.style.border = "2px solid red";
          setIsValidate({ ...isValidate, reason: false });
        } else {
          reason.current.parentNode.style.border = "2px solid green";
          setIsValidate({ ...isValidate, reason: true });
        }
        break;

      default:
        break;
    }
  };

  const handleInput = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    validate(e.target.name, e.target.value);
  };

  const handleForm = async (e) => {
    e.preventDefault();
    try {
      if (isValidate.name && isValidate.mobile && isValidate.reason) {
        const res = await AppointmentPostAction(input);
        console.log(res);
        debugger
        if (res.data) {
          sendMail(input, "appointment");
          setInput({
            name: "",
            mobile: "",
            email: "",
            reason: "",
          });

          name.current.parentNode.style.border = "none";
          email.current.parentNode.style.border = "none";
          number.current.parentNode.style.border = "none";
          reason.current.parentNode.style.border = "none";
          navigate("/greet");
        } else {
          addToast("something went worng", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      } else {
        addToast("Please Fill all the Fields!", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (error) {
      addToast(error, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <div className="contact_wrp">
      <div className="form_details_wrp">
        <div className="contact_form_wrp">
          <div className="contact_heading">
            <h1>
              Book your appointment with Ear, Nose and Throat specialist in
              Gurdaspur only on <span>Few Minutes</span>
            </h1>
          </div>
          <form onSubmit={handleForm} className="form_input_fields">
            <div className="input_fields">
              <div className="Input_details">
                <label htmlFor="name">
                  Your Name <span>*</span>
                </label>
                <input
                  ref={name}
                  value={input.name}
                  type="text"
                  name="name"
                  id="name"
                  onChange={handleInput}
                  placeholder=""
                />
              </div>

              <div className="Input_details">
                <label htmlFor="email">Your Email</label>
                <input
                  ref={email}
                  value={input.email}
                  type="email"
                  name="email"
                  id="email"
                  onChange={handleInput}
                  placeholder=""
                />
              </div>
            </div>
            <div className="input_fields">
              <div className="Input_details">
                <label htmlFor="mobile">
                  Your Mobile <span>*</span>
                </label>
                <input
                  ref={number}
                  required
                  value={input.mobile}
                  maxLength="10"
                  type="tel"
                  name="mobile"
                  id="mobile"
                  onChange={handleInput}
                  placeholder=""
                />
              </div>

              <div className="Input_details">
                <label htmlFor="reason">
                  Diseases <span>*</span>
                </label>
                <select
                  ref={reason}
                  value={input.reason}
                  type="text"
                  id="reason"
                  name="reason"
                  onChange={handleInput}
                >
                  <option value="">Select Disease</option>
                  <option value="PHYSICAL_DISEASES">Physical diseases</option>
                  <option value="MENTAL_DISEASES">Mental diseases</option>
                  <option value="INFECTION_DISEASESF">
                    Infection diseases
                  </option>
                </select>
              </div>
            </div>
            <button> Make Appointment</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
