import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
};

const spinnerSlice = createSlice({
  name: "spinner",
  initialState,

  reducers: {
    spinnerAction: (state, actions) => {
        state.loading = actions.payload
    },
  },
});

export const { spinnerAction } = spinnerSlice.actions;

export default spinnerSlice.reducer;
