import React from "react";
import "./Faq.scss";
import { Accordion } from "react-bootstrap";

const FAQ = () => {
  const data = [
    {
      heading: "Q1. Which are the routine ENT surgeries?",
      details:
        "Some of the routine ENT surgical procedures are Tonsillectomy and Adenoidectomy, Myringotomy with tube insertion and functional endoscopic Sinus surgery. ",
    },
    {
      heading: "Q2. What is the role of an ENT surgeon?",
      details:
        "An ENT surgeon treats conditions related to hearing, smell, taste and other related infections. An ENT is also responsible for treating balancing problems like Vertigo.",
    },
    {
      heading: "Q3. How does an ENT doctor examine your ears?",
      details:
        "A painless procedure ensures a thorough diagnosis of the ears. A tube named Otoscope is inserted into the ear with tiny light to have a closer view of the ear canal. This procedure is relatively painless with an assured diagnosis. ",
    },
    {
      heading: "Q4. What are the common ENT problems?",
      details:
        "The common ENT problem is hearing loss. The second on that list is Dysphagia ( Difficult swallowing), ear infections, Gastric reflux and so on. ",
    },
  ];

  return (
    <div id="faq" className="faq_wrp">
      <div className="about_FAQ">
        <h6 className="about_faq_heading">FAQ</h6>
        <h1>frequently asked question</h1>
        <Accordion className="faq_according_wrap">
          {data.map((item, index) => {
            return (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header>{item.heading}</Accordion.Header>
                <Accordion.Body>{item.details}</Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

export default FAQ;
