import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ClassicEditor from '../../../ckeditor';

import "./Editor.scss";

const Editor = ({html, setHtml}) => {
  // const [html, setHtml] = useState("");
  // const editorRef = useRef();
  // const [isLoadedEditor, setIsLoadedEditor] = useState(false);
  
  // const { CKEditor, ClassicEditor } = editorRef.current || {};

  // useLayoutEffect(() => {
  //   editorRef.current = {
  //     CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
  //     ClassicEditor: require("../../../ckeditor.js"),
  //   };
  //   setIsLoadedEditor(true);

  //   return () => {
  //     editorRef.current = {};
  //   };
  // }, []);

  return (
    <div className="editor_wrp">
      {true && (
        <CKEditor
          editor={ClassicEditor}
          data={html}
          onChange={(event, editor) => {
            setHtml(editor.getData());
          }}
        />
      )}
    </div>
  );
};

export default Editor;
