import React, { useEffect, useState } from "react";
import "./Gallery.scss";
import Image from "./Image";
import Tabs from "./Tabs";
import { useToasts } from "react-toast-notifications";

import Loader from "../Loader";
import UploadImageModal from "../UploadImageModal";
import CategoryModal from "../CategoryModal";
import { useSelector } from "react-redux";
import {
  GalleryGetAction,
  ImageDeleteAction,
  CategoryGetOnlyHaveImages,
} from "../../../Api/gallery";

const Gallery = ({ container, isPublic }) => {
  const [images, setImages] = useState([]);
  const [activeTab, setActiveTab] = useState("id0");
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [updateImage, setUpdateImage] = useState();
  const [tabsData, setTabsData] = useState([]);
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const spinner = useSelector((state) => state.spinnerReducer);
  const adminLoginReducer = useSelector((state) => state.adminLoginReducer);
  const { addToast } = useToasts();

  // const getTabs = async () => {
  //   setIsLoading(true);
  //   setActiveTab("id0");
  //   const res = await CategoryGetAction();
  //   setTabsData(res.data);
  //   setIsLoading(false);
  //   const tabId = res.data[0].id;
  //   return tabId;
  // };

  const getTabsHaveImage = async () => {
    setIsLoading(true);
    setActiveTab("id0");
    const res = await CategoryGetOnlyHaveImages();
    let newArr = [];
    let newArr1 = [];

    newArr = res.data.map((item, index) => {
      if (!newArr1.includes(item?.category)) {
        newArr1.push(item.category);
        return { id: item?.category, name: item?.category_name };
      }

      return "";
    });

    setTabsData(newArr);

    return newArr[0]?.id;
  };

  const getImages = async (id) => {
    setIsLoading(true);
    if (id) {
      const res = await GalleryGetAction(id);
      setImages(res.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (updateImage) {
      getImages(updateImage);
    }
  }, [updateImage]);

  useEffect(() => {
    getTabsHaveImage().then((id) => getImages(id));
  }, [spinner]);

  const handleId = (event) => {
    setActiveTab(event.target.id);
  };

  const handleDelete = async (id, category) => {
    try {
      const data = await ImageDeleteAction(id, adminLoginReducer.token);
      if (!data.data) {
        setUpdateImage(category);
        addToast("Deleted!", {
          appearance: "success",
          autoDismiss: true,
        });
      } else {
        addToast("something went worng", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (error) {
      console.log("gsdhgshgd");
    }
  };

  return (
    <div
      className={`gallery_wrp ${
        container === "admin-login" ? "admin-login" : ""
      }`}
    >
      <div className="tabs_wrp">
        <Tabs
          getData={getImages}
          activeTab={activeTab}
          handleId={handleId}
          tabs={tabsData}
        />
        {container === "admin-login" && (
          <div className="flex flex-column justify-content-start">
            <div
              onClick={() => setShowCategoryModal(true)}
              className="addImage_wrp d-flex align-items-center my-2"
            >
              <div className="category"></div> Category
            </div>
            <div
              onClick={() => setShowModal(true)}
              className="addImage_wrp d-flex align-items-center my-2"
            >
              <div className="addImage"></div> Add Image
            </div>
          </div>
        )}
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="image_wrp row">
          {images.length === 0 ? (
            <>
              <p className="text-center">No Images Found</p>
            </>
          ) : (
            images.map((item, index) => {
              return (
                <Image
                  key={new Date().getTime() + index}
                  isPublic={isPublic}
                  src={`${imgUrl}${item?.image}`}
                  handleDelete={() => handleDelete(item.id, item.category)}
                />
                // <Image
                //   key={new Date().getTime() + index}
                //   isPublic={isPublic}
                //   src={item.src?.small}
                //   handleDelete={() => handleDelete(item.id, item.category)}
                // />
              );
            })
          )}
        </div>
      )}
      <UploadImageModal show={showModal} onHide={() => setShowModal(false)} />
      <CategoryModal
        show={showCategoryModal}
        onHide={() => setShowCategoryModal(false)}
      />
    </div>
  );
};

export default Gallery;
