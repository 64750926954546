import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Pagination from "react-js-pagination";
import {
  ContactUsGetApi,
  ContactUsGetAllData,
} from "../../../../Api/ContactUs";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import CSVDownload from "react-json-to-csv";
import "../dashboard.scss";

const ContactUs = () => {
  const [state, setState] = useState(1);
  const [conatctList, setContactList] = useState();
  const { addToast } = useToasts();
  const [downloadData, setDownloadData] = useState([]);

  const handlePageChange = async (pageNumber) => {
    try {
      setState(pageNumber);
      var token = adminLoginReducer.token;
      const contacts = await ContactUsGetApi(`?page=${pageNumber}`, token);
      setContactList(contacts.data);
    } catch (error) {
      addToast("something went worng", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const adminLoginReducer = useSelector((state) => state.adminLoginReducer);

  useEffect(() => {
    const getAllData = async () => {
      const res = await ContactUsGetAllData(adminLoginReducer.token);
      setDownloadData(res.data.all_data);
    };
    getAllData();
    handlePageChange(1);
  }, []);
  return (
    <div>
      <CSVDownload
        className="export_btn"
        data={downloadData}
        filename="Contacts.csv"
      >
        Export
      </CSVDownload>
      <div style={{ overflowX: "auto", width: "100%" }}>
        <Table striped bordered hover variant="ligth">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone </th>
              <th>Subject</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {conatctList &&
              conatctList.data.map((elem, index) => {
                return (
                  <tr key={elem.id}>
                    <td>{index + 1}</td>
                    <td>{elem.name}</td>
                    <td>{elem.email}</td>
                    <td>{elem.phone}</td>
                    <td>{elem.subject}</td>
                    <td>{elem.message}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      <div className="footer">
        {conatctList && (
          <Pagination
            activePage={state}
            itemsCountPerPage={10}
            totalItemsCount={conatctList.total_contacts}
            pageRangeDisplayed={
              conatctList.total_page > 5 ? 5 : conatctList.total_page
            }
            innerClass="innerClassForPagination"
            activeClass="activeClassForPagination"
            itemClass="itemClassForPagination"
            activeLinkClass="activeLinkClassForPagination"
            onChange={(page) => handlePageChange(page)}
          />
        )}
        <span>
          {conatctList &&
            conatctList.current_page * 10 - (10 - conatctList.data.length)}{" "}
          out of {conatctList && conatctList.total_contacts}
        </span>
      </div>
    </div>
  );
};

export default ContactUs;
