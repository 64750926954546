import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Column, Row } from "simple-flexbox";
import {
  SidebarComponent,
  SidebarContext,
} from "../../components/ui/Dashboard/sidebar";
import HeaderComponent from "../../components/ui/Dashboard/header/HeaderComponent";
import AppointmentList from "../../components/ui/Dashboard/appointment-list";
import { Route, Routes } from "react-router-dom";
import PhotoGallery from "../../components/ui/Dashboard/gallery";
import AboutUs from "../../components/ui/Dashboard/AboutUs";
import ContactUs from "../../components/ui/Dashboard/ContactUs";
// import Subscribe from "../../components/ui/Dashboard/subscribe";
import DashboardContaint from "../../components/ui/Dashboard/dashboardContaint";
import ResourceAdmin from "../../components/ui/Dashboard/resourceDashboard";

const useStyles = createUseStyles({
  container: {
    height: "100%",
    overflow: "hidden",
  },
  mainBlock: {
    marginLeft: 255,
    padding: 30,
    overflow: "hidden",
    "@media (max-width: 1080px)": {
      marginLeft: 0,
    },
  },
  contentBlock: {
    marginTop: 20,
  },
});

const DasboardContainer = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <>
      <SidebarContext>
        <Row className={classes.container}>
          <SidebarComponent />
          <Column flexGrow={1} className={classes.mainBlock}>
            <HeaderComponent />
            <div className={classes.contentBlock}>
              <Routes>
                <Route path="" element={<DashboardContaint />} />
                <Route path="appointment" element={<AppointmentList />} />
                <Route path="gallery" element={<PhotoGallery />} />
                <Route path="about" element={<AboutUs />} />
                <Route path="contact" element={<ContactUs />} />
                <Route path="resource" element={<ResourceAdmin />} />
                {/* <Route path="subscribe" element={<Subscribe />} /> */}
                {/* <Route path="*" element={<Navigate to="/dashbord"/>} /> */}
              </Routes>
            </div>
          </Column>
        </Row>
      </SidebarContext>
    </>
  );
};

export default DasboardContainer;
