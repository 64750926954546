const slug = {
    dashboard: '/dashboard',
    gallery: '/dashboard/gallery',
    contact: '/dashboard/contact',
    appointment: '/dashboard/appointment',
    about: '/dashboard/about',
    resource: '/dashboard/resource'
}


export default slug