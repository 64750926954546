import axios from "axios";
const url = process.env.REACT_APP_URL;
export const AboutPostAction = async (data, token) => {
  try {
    const res = await axios.post(`${url}about/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};
export const AboutGetAction = async () => {
  const xhr = await axios.request({
    method: "get",
    url: `${url}about/`,
  });
  return xhr;
};
