import React from "react";

const Tabs = ({ getData, tabs, activeTab, handleId }) => {
  const handleClick = (event, query) => {
    getData(query);
    handleId(event);
  };

  return (
    <div className="tabs">
      {tabs &&
        tabs.map((item, id) => {
          return (
            <React.Fragment key={new Date().getTime() + id}>
              {item && (
                <button
                  id={`id${id}`}
                  onClick={(event) => handleClick(event, item.id)}
                  className={`tab ${activeTab === `id${id}` ? "active" : ""}`}
                >
                  {item.name}
                </button>
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default Tabs;
