import React, { useState, useEffect } from "react";
import "./About.scss";
import Editor from "../../Editor";
import btnIcon from "../../../../images/about-button-icon.webp";
import { AboutPostAction, AboutGetAction } from "../../../../Api/AboutApi";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

const AboutUs = () => {
  const [aboutUs, setAboutUs] = useState("");
  const [ourMission, setOurMission] = useState("");
  const [ourApproach, setOurApproach] = useState("");
  const adminLoginReducer = useSelector((state) => state.adminLoginReducer);
  const { addToast } = useToasts()
  const handleUpdateAbout = async (e) => {
    try {
      const res = await AboutPostAction(
        {
          about_us: aboutUs,
          our_mission: ourMission,
          our_approach: ourApproach,
        },
        adminLoginReducer.token
      );

      if (res) {
        addToast("Updated!", {
          appearance: "success",
          autoDismiss: true,
        });
      } else {
        addToast("Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (error) {
      addToast("Something went wrong!", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  useEffect(() => {
    const getAboutData = async () => {
      const res = await AboutGetAction();
      const { about_us, our_mission, our_approach } = res.data;
      setAboutUs(about_us);
      setOurMission(our_mission);
      setOurApproach(our_approach);
    };
    getAboutData();
  }, []);

  return (
    <div className="admin_about_us_wrp">
      <div className="input_group">
        <h2>About Us</h2>
        <Editor html={aboutUs} setHtml={setAboutUs} />
      </div>
      <div className="input_group my-4">
        <h2>Our Vision & Mission</h2>
        <Editor html={ourMission} setHtml={setOurMission} />
      </div>
      <div className="input_group">
        <h2>Our Approach</h2>
        <Editor html={ourApproach} setHtml={setOurApproach} />
        <button className="submitBtn" onClick={handleUpdateAbout}>
          Update <img src={btnIcon} alt="icon" />
        </button>
      </div>
    </div>
  );
};

export default AboutUs;
