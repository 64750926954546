import React from "react";
import deleteBtn from '../../../../images/trash-2.webp'

const Image = (props) => {
  const { src, handleDelete } = props;
  return (
    <>
      <div className="image col-lg-3 col-md-4 col-sm-6 col-12">
        <img src={src} alt="gallery" />
        {
          !props.isPublic && <button onClick={handleDelete}><img src={deleteBtn} alt="icon" /></button>
        }
      </div>
    </>
  );
};

export default Image;
