import React from "react";
import DashboardComponent from "./DasboardContainer";

const DashboardContainer = () => {
  return (
    <div className="dashboard_container_wrp">
      <DashboardComponent />
    </div>
  );
};

export default DashboardContainer;
