import React from "react";
import "./feedback.scss";
import feedBackUserImage from "../../../images/user.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination, Navigation } from "swiper";

const Feedback = () => {
  return (
    <div className="feedback_wrp">
      <div className="top">
        <h6>Feedback</h6>
        <h1>Learn what our patients have to say.</h1>
        
      </div>

      <div className="down">
        <Swiper
          effect={"coverflow"}
          spaceBetween={80}
          centeredSlides={true}
          slidesPerView={1}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2,
            slideShadows: true,
          }}
          loop={true}
          navigation={true}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="slider"
        >
          <SwiperSlide>
            <div className="slides">
              <p>Prashant Singh</p>
              <p>patient</p>
              <p>
                This work presents a two-microphone speech enhancement (SE)
                framework based on basic recurrent neural network (RNN) cell.
                The proposed method operates in real-time, improving the speech
                quality and intelligibility in noisy environments. The RNN model
                trained using a simple feature set—real and imaginary parts of
                the short-time Fourier transform (STFT) are computationally
                efficient with a minimal input-output processing delay. The
                proposed algorithm can be used in any stand-alone platform such
                as a smartphone using its two inbuilt microphones. The detailed
                operation of the real-time implementation on the smartphone is
                presented. The developed application works as an assistive tool
                for hearing aid devices (HADs). Speech quality and
                intelligibility test results are used to compare the proposed
                algorithm to existing conventional and neural network-based SE
                methods. Subjective and objective scores show the superior
                performance of the developed method over several conventional
                methods in different noise conditions and low signal to noise
                ratios (SNRs).
              </p>
              <div className="image">
                <img src={feedBackUserImage} alt="user" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slides">
              <p>Rohan Ambani</p>
              <p>patient</p>
              <p>
                Despite great advances in hearing-aid technology, users still
                experience problems with noise in windy environments. The
                potential benefits of using a deep recurrent neural network
                (RNN) for reducing wind noise were assessed. The RNN was trained
                using recordings of the output of the two microphones of a
                behind-the-ear hearing aid in response to male and female speech
                at various azimuths in the presence of noise produced by wind
                from various azimuths with a velocity of 3 m/s, using the
                “clean” speech as a reference. A paired-comparison procedure was
                used to compare all possible combinations of three conditions
                for subjective intelligibility and for sound quality/comfort.
                The conditions were: unprocessed noisy speech; noisy speech
                processed using the RNN; and noisy speech that was high-pass
                filtered (which also reduced wind noise). Eighteen native
                English-speaking participants were tested, nine with normal
                hearing and nine with mild-to-moderate hearing impairment.
                Frequency-dependent linear amplification was provided for the
                latter. Processing using the RNN was significantly preferred
                over no processing by both subject groups for both subjective
                intelligibility and sound quality, although the magnitude of the
                preferences was small. High-pass filtering was not significantly
                preferred over no processing. Although RNN was significantly
                preferred over HPF only for sound quality for the
                hearing-impaired participants, for the results as a whole there
                was a preference for RNN over HPF. Overall, the results suggest
                that reduction of wind noise using an RNN is possible and might
                have beneficial effects when used in hearing aids.F
              </p>
              <div className="image">
                <img src={feedBackUserImage} alt="user" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slides">
              <p>Kapil Roy</p>
              <p>patient</p>
              <p>
                The sound quality of a turn signal sound is directly related to
                the driving safety and influences comfort during driving. A turn
                signal sound and interior noise always exist simultaneously
                while driving. However, none of the studies are performed to
                observe and control the change of a turn signal sound when the
                vehicle interior noise was changed. Therefore, this paper
                develops a method to evaluate and control the warning effect of
                a turn signal sound masked by vehicle interior noise. First, the
                turn signal sound and the vehicle interior noise at different
                speeds are measured and the time-frequency characteristics of
                the turn signal sound is analyzed. Second, the pairwise
                comparison method is applied to subjectively evaluate the
                warning effect of the sounds and the warning effect of the turn
                signal sound at 6 speeds relative to that at 40 km/h was
                obtained. Third, a new hearing threshold evaluating the warning
                effect is developed and used as a sound metric. This metric is
                employed to evaluate objectively the warning effect of warning
                sounds at different speeds. The subjective evaluation results
                are consistent with the objective evaluation results, which
                confirms the validity of objective evaluation. To keep the
                warning effect of the warning sound during the driving, two
                methods of controlling the turn signal sound are proposed and
                will be researched further in the in the future.
              </p>
              <div className="image">
                <img src={feedBackUserImage} alt="user" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="slides">
              <p>Rajesh Verma</p>
              <p>patient</p>
              <p>
                Many hearing aid users are negatively impacted by wind noise
                when spending time outdoors. Turbulent airflow around hearing
                aid microphones caused by the obstruction of wind can result in
                noise that is not only perceived as annoying but may also mask
                desirable sounds in the listening environment, such as speech.
                To mitigate the adverse effects of wind noise, hearing aid
                developers have introduced several technological solutions to
                reduce the amount of wind noise at the hearing aid output. Some
                solutions are based on mechanical modifications; more recently,
                sophisticated signal processing algorithms have also been
                introduced. By offering solutions to the wind noise problem,
                these signal processing algorithms can promote more optimal use
                of hearing aids during outdoor activities. This article reviews
                how wind noise is generated in hearing aids, outlines the
                technological challenges in wind noise management, and
                summarizes the technological solutions that have been proposed
                and/or implemented in modern hearing aids.
              </p>
              <div className="image">
                <img src={feedBackUserImage} alt="user" />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Feedback;
