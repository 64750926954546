import axios from "axios";
const url = process.env.REACT_APP_URL
export const AppointmentPostAction = async (data) => {
    try{
  const res = await axios.post(
    `${url}book-an-appointment/`,
    data
  );
  return res
    }catch(error) {
        return error
    }
};
export const AppointmentGetAction = async (page,token) => {
    const xhr = await axios.request({
        method: "get",
        url: `${url}book-an-appointment/${page}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return xhr;
  };
  
export const AppointmentGetTotalData = async (token) => {
    const xhr = await axios.request({
        method: "get",
        url: `${url}book-an-appointment/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return xhr;
  };

