import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
};

const adminLoginSlice = createSlice({
  name: "adminLogin",
  initialState,

  reducers: {
    adminLoginAction: (state, actions) => {
        state.token = actions.payload.access
    },
  },
});

export const { adminLoginAction } = adminLoginSlice.actions;

export default adminLoginSlice.reducer;
