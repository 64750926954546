import axios from "axios";
const url = process.env.REACT_APP_URL;

export const ResourcePostAction = async (data, token) => {
  try {
    const xhr = await axios.request({
      method: "post",
      url: `${url}resource/`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data,
    });
    return xhr;
  } catch (error) {
    return error;
  }
};

export const ResourceGetAction = async (page) => {
  try {
    const xhr = await axios.get(`${url}resource/?page=${page}`);
    return xhr;
  } catch (error) {
    return error;
  }
};

export const ResourceGetWithOutPageAction = async () => {
  try {
    const xhr = await axios.get(`${url}resource/`);
    return xhr;
  } catch (error) {
    return error;
  }
};

export const ResourceDeleteAction = async (data, token) => {
  try {
    const xhr = await axios.request({
      method: "delete",
      url: `${url}resource/${data}/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return xhr;
  } catch (error) {
    return error;
  }
};

export const ResourceGetBeforeUpdate = async (id) => {
  try {
    const xhr = await axios.get(`${url}resource/${id}/`);
    return xhr;
  } catch (error) {
    console.log(error);
  }
};

export const ResourceUpdateAction = async (id, data, token) => {
  try {
    const xhr = await axios.request({
      method: "put",
      url: `${url}resource/${id}/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      },
      data: data,
    });
    return xhr;
  } catch (error) {
    console.log(error);
  }
};
