import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import SLUGS from '../../../../resources/slugs';
import {
  IconContacts,
  IconIdeas,
  IconLogout,
  IconOverview,
  IconSubscription,
  IconTickets,
} from "../../../../assets/icons";
import LogoComponent from "./LogoComponent";
import Menu from "./MenuComponent";
import MenuItem from "./MenuItemComponent";
import { adminLoginAction } from "../../../../redux/persist/admin-login-slice";
import { useDispatch } from "react-redux";

const useStyles = createUseStyles({
  separator: {
    borderTop: ({ theme }) => `1px solid ${theme.color.lightGrayishBlue}`,
    marginTop: 16,
    marginBottom: 16,
    opacity: 0.06,
  },
});

function SidebarComponent() {
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles({ theme });
  const isMobile = window.innerWidth <= 1080;
  const dispatch = useDispatch();
  async function logout() {
    dispatch(adminLoginAction(""));
    navigate("/admin");
  }

  function onClick(route) {
    navigate(route);
  }

  return (
    <Menu isMobile={isMobile}>
      <div style={{ paddingTop: 30, paddingBottom: 30 }}>
        <LogoComponent />
      </div>
      <MenuItem
        id={SLUGS.dashboard}
        title="Dashboard"
        icon={IconSubscription}
        onClick={() => onClick("")}
      />
      <MenuItem
        id={SLUGS.gallery}
        title="Gallery"
        icon={IconOverview}
        onClick={() => onClick("gallery")}
      />
      <MenuItem
        id={SLUGS.appointment}
        title="Appointment"
        icon={IconTickets}
        onClick={() => onClick("appointment")}
      />
      <MenuItem
        id={SLUGS.about}
        title="About Us"
        icon={IconIdeas}
        onClick={() => onClick("about")}
      ></MenuItem>
      <MenuItem
        id={SLUGS.contact}
        title="Contacts"
        icon={IconContacts}
        onClick={() => onClick("contact")}
      />
      <MenuItem
        id={SLUGS.resource}
        title="Resources"
        icon={IconOverview}
        onClick={() => onClick("resource")}
      />
      <div className={classes.separator}></div>
      <MenuItem id="logout" title="Logout" icon={IconLogout} onClick={logout} />
    </Menu>
  );
}

export default SidebarComponent;
