import React from "react";
import AdminLoginContainer from "../../containers/AdminLoginContainer";

const AdminLogin = () => {
  return (
    <>
      <AdminLoginContainer />
    </>
  );
};

export default AdminLogin;
