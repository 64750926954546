import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "reduxjs-toolkit-persist/integration/react";
import { store, persistor } from "./redux/store";
import { ToastProvider } from "react-toast-notifications";
import Dashboard from "./page/dashborad";
import { ThemeProvider } from "react-jss";
import Theme from "./resources/theme";
import AdminLogin from "./page/adminlogin";
import { HelmetProvider } from "react-helmet-async";
const domNode = document.getElementById("root");
const app = (
  <ToastProvider>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={Theme}>
            <HelmetProvider>
              <Router>
                <Routes>
                  <Route path="/*" element={<App />} />
                  <Route path="/dashboard/*" element={<Dashboard />} />
                  <Route path="/admin" element={<AdminLogin />} />
                </Routes>
              </Router>
            </HelmetProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </ToastProvider>
);

// const root = ReactDOM.createRoot(domNode)
const root = ReactDOM.hydrateRoot(domNode, app)
root.render(app)
 