import React from 'react'
import "./ContactNo.scss" 
import QueIcon from "../../../images/quetions.webp"
const ContactNo = () => {
  return (
    <div className='contact_no_wrp'>
    <div className='contact_no_details'>
        <img src={QueIcon} alt="queIcon"/>
        <p>Need a doctor for check-up? Call for an Emergency Service!</p>
        <a href="tel:9417281799">+91 94172 81799</a>
    </div>
    </div>
  )
}

export default ContactNo