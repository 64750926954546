import React from "react";
import Card from "./Card";
import "./Specialities.scss";

const Specialities = () => {

  const data = [
    {
      heading: 'Ear, Nose and Throat OPD',
      text: '<p>Visit Kashyap ENT clinic and avail excellent ENT medicine care by expert consultants.</p>'
    },
    {
      heading: 'Hearing Aid Services',
      text: '<p>Relish the joy of sound with sleek and elegant hearing aid devices for a comfortable hearing experience.</p>'
    },
    {
      heading: 'Hearing testing services',
      text: '<p>Catch the hearing loss issue before an alarming condition arises with routine online hearing testing.</p>'
    },
    {
      heading: 'Treatment for Vertigo',
      text: '<p>Win over the problem of Vertigo with the specialists and ensure the well-being of your loved ones.</p>'
    },
    {
      heading: 'Treatment for Congenital Deformities',
      text: '<p>We identify congenital problems at the earliest and channel our professional doctors for exceptional treatment planning.</p>'
    }
  ]

  return (
    <div className="specialities_wrp">
      <div className="top">
        <h6>Our Specialities</h6>
        <h1>Our Broad-Spectrum Services</h1>
        <p>
        Excellence and affordable treatment is our goal. We care for adults and children with a sensitive and holistic approach.
        </p>
      </div>
      <div className="down">
        <div className="row">
          <Card container={'home'} data={data}/>
        </div>
      </div>
    </div>
  );
};

export default Specialities;
