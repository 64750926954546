import axios from "axios"
const url =process.env.REACT_APP_URL
export const ContactUsPostApi = async(data)=>{
    const res = await axios.post(
        `${url}contact/`,
        data
      );
      return res
}
export const ContactUsGetApi = async (page,token) => {
  const xhr = await axios.request({
    method: "get",
    url: `${url}contact/${page}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return xhr;
};

export const ContactUsGetAllData = async (token) => {
  const xhr = await axios.request({
    method: "get",
    url: `${url}contact/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return xhr;
};