import React from "react";
// import Resource from '../../resourceComponent'
import { Table } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import { AiOutlineDelete, AiFillFilePdf, AiFillFileAdd } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { BsImage } from "react-icons/bs";
import "./ResourceDashboard.scss";
import { useToasts } from "react-toast-notifications";

import { useState } from "react";
import UploadResourceModal from "../UploadResourceModal";
import { useEffect } from "react";
import {
  ResourceGetAction,
  ResourceDeleteAction,
} from "../../../../Api/Resource";
import { useSelector, useDispatch } from "react-redux";
import { spinnerAction } from "../../../../redux/spinner";

const ResourceAdmin = () => {
  const [activePage, setActivePage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [resource, setResource] = useState();
  const [updateResourceId, setUpdateResourceId] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [deleteResourceId, setDeleteResourceId] = useState('')
  const dispatch = useDispatch();
  const state = useSelector((state) => state.adminLoginReducer);
  const spinner = useSelector((state) => state.spinnerReducer);
  const token = state.token;
  const { addToast } = useToasts();

  const handleFileEdit = (id) => {
    setUpdateResourceId(id);
    setIsUpdate(true);
    setShowModal(true);
  };

  const handleFileDelete = async (id) => {
    dispatch(spinnerAction(true));
    try {
      const res = await ResourceDeleteAction(deleteResourceId, token);
      if (res) {
        dispatch(spinnerAction(false));
        addToast("Resource Deleted successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        setShowModalConfirmation(false);
      } else {
        addToast("Some Error", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (err) {
      addToast("Some Error", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const getResource = async (page) => {
    try {
      setActivePage(page);
      const res = await ResourceGetAction(page);
      setResource(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePageChange = (page) => {
    getResource(page);
  };

  useEffect(() => {
    getResource(1);
  }, [spinner]);

  return (
    <div className="resource_admin_wrp">
      <div className="resource_header">
        <button
          onClick={() => {
            setIsUpdate(false);
            setShowModal(true);
          }}
        >
          <AiFillFileAdd />
          Add
        </button>
      </div>
      <Table striped bordered hover variant="ligth">
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Description</th>
            <th>Image </th>
            <th>File </th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {resource &&
            resource.data.map((elem, index) => {
              return (
                <tr key={new Date().getTime() + index}>
                  <td>{index + 1}</td>
                  <td>{elem.title}</td>
                  <td>{elem.description}</td>
                  <td>
                    <a
                      target="__blank"
                      href={`${process.env.REACT_APP_IMG_URL}${elem.image}`}
                    >
                      <BsImage />
                    </a>
                  </td>

                  <td>
                    <a
                      target="__blank"
                      href={`${process.env.REACT_APP_IMG_URL}${elem.pdf_file}`}
                    >
                      <AiFillFilePdf />
                    </a>
                  </td>
                  <td>
                    <button onClick={() => handleFileEdit(elem.id)}>
                      <BiEdit />
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        setDeleteResourceId(elem.id)
                        setShowModalConfirmation(true);
                      }}
                    >
                      <AiOutlineDelete />
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <div className="footer">
        {resource && (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={resource.total_resources}
            pageRangeDisplayed={
              resource.total_page > 5 ? 5 : resource.total_page
            }
            innerClass="innerClassForPagination"
            activeClass="activeClassForPagination"
            itemClass="itemClassForPagination"
            activeLinkClass="activeLinkClassForPagination"
            onChange={(page) => handlePageChange(page)}
          />
        )}
      </div>
      <UploadResourceModal
        show={showModal}
        onHide={() => setShowModal(false)}
        isUpdate={isUpdate}
        updateResourceId={updateResourceId}
      />

      <Modal
        show={showModalConfirmation}
        onHide={() => setShowModalConfirmation(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="upload_resource_modal_wrp"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="upload_resource_wrp">
            <h3 className="text-center">Are You Want to Delete</h3>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            variant="secondary"
            onClick={() => setShowModalConfirmation(false)}
          >
            Close
          </button>
          <button variant="danger" onClick={handleFileDelete}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ResourceAdmin;
