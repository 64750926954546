import React, { useState, useRef } from "react";
import "./AdminLoginPage.scss";
import { useToasts } from "react-toast-notifications";
import logo from "../../../images/logo.webp";
import btnIcon from "../../../images/about-button-icon.webp";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { adminLoginAction } from "../../../redux/persist/admin-login-slice";
import { AdminPostPage } from "../../../Api/AdminPageApi";
// import { Spinner } from "react-bootstrap";

const AdminLoginPage = () => {
  const username = useRef();
  const password = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, setLogin] = useState({ username: "", password: "" });
  const { addToast } = useToasts();
  const [isValidate, setIsValidate] = useState({});
  // const [isLoad, setIsLoad] = useState(true);
  // const state = useSelector((state) => state.adminLoginReducer);

  const validate = (type, value) => {
    switch (type) {
      case "username":
        if (value.length < 3) {
          username.current.parentNode.style.border = "2px solid red";
          setIsValidate({ ...isValidate, username: false });
        } else {
          username.current.parentNode.style.border = "2px solid green";
          setIsValidate({ ...isValidate, username: true });
        }
        break;
      case "password":
        if (value.length < 4) {
          password.current.parentNode.style.border = "2px solid red";
          setIsValidate({ ...isValidate, password: false });
        } else {
          password.current.parentNode.style.border = "2px solid green";
          setIsValidate({ ...isValidate, password: true });
        }
        break;
      default:
        break;
    }
  };

  // const checkUser = () => {
  //   if(state.token){
  //     navigate('dasboard')
  //   }else{
  //     setIsLoad(false)
  //   }
  // }

  // checkUser()

  const handleInput = (e) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
    validate(e.target.name, e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isValidate.password) {
        const token = await AdminPostPage(login);
        if (token.data.token) {
          addToast("Successful LoggedIn!", {
            appearance: "success",
            autoDismiss: true,
          });

          setLogin({
            username: "",
            password: "",
          });

          username.current.parentNode.style.border = "none";
          password.current.parentNode.style.border = "none";

          dispatch(adminLoginAction(token.data.token));
          navigate("/dashboard");
        } else {
          addToast("something went worng", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      } else {
        addToast("Please Fill all the Fields!", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (error) {
      addToast("error", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  // if (isLoad) {
  //   return (
  //     <div className="admin_login_wrp">
  //       <Spinner animation="border" variant="danger" />
  //     </div>
  //   );
  // }

  return (
    <div className="admin_login_wrp">
      <div className="login_card">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <h2>Sign In</h2>
        <form>
          <div className="input_wrp">
            <label htmlFor="username">
              username <span>*</span>
            </label>
            <input
              type="username"
              name="username"
              ref={username}
              required
              value={login.username}
              id="username"
              onChange={handleInput}
            />
          </div>
          <div className="input_wrp">
            <label htmlFor="password">
              Password <span>*</span>
            </label>
            <input
              minLength="6"
              type="password"
              required
              name="password"
              ref={password}
              value={login.password}
              id="password"
              onChange={handleInput}
            />
          </div>

          <button onClick={handleFormSubmit}>
            Login
            <img src={btnIcon} alt="button" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminLoginPage;
