import React from "react";
import { useSelector } from "react-redux";
import DashboardContainer from "../../containers/DashboardContainer";
import { Navigate } from "react-router-dom";
const Dashboard = () => {
  const adminLoginReducer = useSelector((state) => state.adminLoginReducer);

  if (!adminLoginReducer.token) {
    return <Navigate to="/admin" />;
  }

  return (
    <>
      <DashboardContainer />
    </>
  );
};

export default Dashboard;
