import emailjs from "@emailjs/browser";

const sendMail = async (data, type) => {
  try {
    if (type === "contact") {
      emailjs.init("CWIrch7MzG9iDybUs");
      await emailjs.send(
        "service_lc8psih",
        "template_k4634o9",
        {
          from_name: data?.name,
          p_name: data?.name,
          p_email: data?.email || 'Not Mentioned!',
          p_mobile: data?.phone,
          p_subject: data?.subject,
          p_message: data?.message
        },
        "CWIrch7MzG9iDybUs"
      );
    } else {
      emailjs.init("CWIrch7MzG9iDybUs");
      const res = await emailjs.send(
        "service_lc8psih",
        "template_8ion7ug",
        {
          from_name: data?.name,
          p_name: data?.name,
          p_email: data?.email || 'Not Mentioned!',
          p_mobile: data?.mobile,
          p_disease: data?.reason,
        },
        "CWIrch7MzG9iDybUs"
      );
      console.log(res);
    }
  } catch (error) {}
};

export default sendMail;
